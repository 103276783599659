export default {
  'numpad*': 106,
  'numpad+': 43,
  numpadadd: 43,
  'numpad-': 109,
  'numpad.': 110,
  'numpad/': 111,
  numlock: 144,
  numpad0: 96,
  numpad1: 97,
  numpad2: 98,
  numpad3: 99,
  numpad4: 100,
  numpad5: 101,
  numpad6: 102,
  numpad7: 103,
  numpad8: 104,
  numpad9: 105
}
